import { GetAuthorizeResponse } from 'types/ApiResponse'
import { useAxios } from 'scripts/api'
import { fullUrl } from 'scripts/fullUrl'

export const useAuthorize = (token: string | null | undefined) => {
  const [{ data, error, loading }] = useAxios<GetAuthorizeResponse>(
    `${fullUrl()}/authorize?token=${token}`,
  )
  return [{ ...data, error, loading }]
}
