import React, { FC } from 'react'
import { postAuthorizeConfirm } from 'api/postAuthorizeConfirm'
import { useSnackbar } from 'presentation/components/Snackbar/useSnackbar'
import { navigate } from 'gatsby'
import { useTranslation } from 'react-i18next'

interface Props {
  location_name: string | undefined
  browser_name: string | undefined
  ip_address: string | undefined
  token: string | undefined | null
}

export const Main: FC<Props> = ({
  browser_name,
  ip_address,
  location_name,
  token,
}) => {
  const [openSnackbar] = useSnackbar()
  const { t } = useTranslation()
  const handleClick = async (confirm: 'allow' | 'deny') => {
    const params = {
      confirm,
      token,
    }
    const { status } = await postAuthorizeConfirm(params)
    if (status !== 200) {
      openSnackbar(
        t('ユーザーの確認に失敗しました。時間を空けて再度お試しください。'),
      )
      return
    }
    switch (confirm) {
      case 'allow':
        navigate('/authorizeConfirmed')
        break
      case 'deny':
        navigate('/passwordResetRequest?snackbar=verifyDeny')
        break
      default:
        openSnackbar(
          t('ユーザーの確認に失敗しました。時間を空けて再度お試しください。'),
        )
        break
    }
  }

  return (
    <>
      <p className="text-xl px-12">
        {t('この端末からのアクセスに心当たりがありますか？')}
      </p>
      <div className="w-full px-6 mt-5">
        <p className="text-xs font-semibold text-gray-700 pb-1">
          {t('サービス名')}
        </p>
        <p className="text-xs">{t('LOCKED')}</p>
      </div>
      <div className="w-full px-6 mt-5">
        <p className="text-xs font-semibold text-gray-700 pb-1">{t('場所')}</p>
        <p className="text-xs">{location_name}</p>
      </div>
      <div className="w-full px-6 mt-5">
        <p className="text-xs font-semibold text-gray-700 pb-1">
          {t('ブラウザ')}
        </p>
        <p className="text-xs">{browser_name}</p>
      </div>
      <div className="w-full px-6 mt-5">
        <p className="text-xs font-semibold text-gray-700 pb-1">{t('IP')}</p>
        <p className="text-xs">{ip_address}</p>
      </div>
      <div className="w-full px-6 mt-5">
        <button
          className="h-12 font-semibold w-full rounded bg-locked text-white hover:opacity-75"
          data-test="buttonAllow"
          onClick={() => handleClick('allow')}
        >
          {t('はい、私です')}
        </button>
      </div>
      <div className="w-full px-6 mt-5">
        <button
          className="h-12 font-semibold w-full rounded bg-white text-red-600 border-red-600 border hover:bg-red-100"
          data-test="buttonDeny"
          onClick={() => handleClick('deny')}
        >
          {t('いいえ、違います')}
        </button>
      </div>
      <div className="px-6 my-6">
        <a
          href="https://www.notion.so/afa58f9f8c7b4e88acfcc43505da543f"
          className="locked-help-link"
          target="_blank"
        >
          {t('ログインに関するヘルプ')}
        </a>
      </div>
    </>
  )
}
