import React from 'react'
import { AuthenticationLayout as Layout } from 'presentation/components/AuthenticationLayout'
import { useAuthorize } from 'api/useAuthorize'
import { Loading } from 'presentation/components/Loading'
import { StringParam, useQueryParam } from 'use-query-params'
import { Main } from './Main'

export const Authorize = () => {
  const [token] = useQueryParam('token', StringParam)
  const [
    { browser_name, ip_address, location_name, status, error, loading },
  ] = useAuthorize(token)

  if (loading) {
    return (
      <Layout>
        <main className="pb-20 pt-10  w-full flex justify-center">
          <Loading />
        </main>
      </Layout>
    )
  }

  if (status === 200)
    return (
      <Layout>
        <Main
          browser_name={browser_name}
          ip_address={ip_address}
          location_name={location_name}
          token={token}
        />
      </Layout>
    )

  return (
    <Layout dataTest="loadedLoading">
      <main className="pb-20 pt-10  w-full flex justify-center">
        <Loading />
      </main>
    </Layout>
  )
}
