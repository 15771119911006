import { fullUrl } from 'scripts/fullUrl'
import { PostAuthorizeConfirmResponse } from 'types/ApiResponse'
import { PostAuthorizeConfirmRequest } from 'types/ApiRequest'
import { axiosClient } from 'scripts/api'

export const postAuthorizeConfirm = async (
  params: PostAuthorizeConfirmRequest,
) => {
  const res = await axiosClient.post<PostAuthorizeConfirmResponse>(
    `${fullUrl()}/authorize/confirm`,
    params,
  )
  return res
}
